import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { IBaseComponent } from "./types";
import { getDeviceListReturnItemType } from "../api";
import debounce from "lodash.debounce";
import { FaTimesCircle, FaTimes } from "react-icons/fa";
import {
  getNoiseLevelColorCode,
  NoiseLevelZoneType,
} from "../util/noise_color_getter";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { MapMarker } from "../components/mapmarker";
import moment from "moment";
import { getANMSData } from "../api/getANMSData";
import Select from "react-select";
import { Redirect } from "react-router-dom";

export const MapView: React.FC<IBaseComponent> = ({
  Devices,
  setSelectedDevice,
  SelectedDevice,
}) => {
  const TagItem = useRef<HTMLDivElement | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [MapInstance, setMapInstance] =
    React.useState<google.maps.Map<Element> | null>(null);
  const mapref = useRef<Element | null>(null);
  const [data, setData] = useState<any>();
  const [SearchQuery, setSearchQuery] = useState<string>("");
  const [FilteredTags, setFilteredTags] =
    useState<getDeviceListReturnItemType[]>(Devices);
  const handleSearch = useCallback(
    debounce(() => {
      if (SearchQuery === "") return Devices;
      setFilteredTags(
        Devices.filter((i) =>
          JSON.stringify(i).toLowerCase().includes(SearchQuery)
        )
      );
    }, 1000),
    [SearchQuery, Devices]
  );
  useEffect(() => {
    setFilteredTags(Devices);
    setSearchQuery("");
  }, [Devices]);

  const getNewData = async () => {
    if (!!SelectedDevice?.dev_id) {
      const result = await getANMSData(SelectedDevice?.dev_id);
      setData(result.data.data);
    }
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // This line disable the blue border

      border: state.isFocused ? "1px solid #acacac" : "1px solid #acacac",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        boxShadow: state.isFocused ? 0 : 0,
      },
    }),
  };
  useEffect(() => {
    if (TagItem.current !== null)
      TagItem.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    if (!!SelectedDevice && !!MapInstance)
      MapInstance.setCenter({
        lat: parseFloat(SelectedDevice.lat),
        lng: parseFloat(SelectedDevice.long),
      });
    getNewData();
  }, [SelectedDevice, MapInstance]);
  const { state } = useSelector((state: RootState) => state.user);
  const DropDownListItems: any[] = React.useMemo(
    () =>
      Devices.map((d) => {
        const item: any = {
          label: `${d.location}, ${d.street}`,
          value: d.dev_id,
        };
        return item;
      }),
    [Devices]
  );
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="MapView bg-gray-700">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA" }}
        defaultCenter={{ lat: 22.562166, lng: 88.409355 }}
        defaultZoom={7}
        resetBoundsOnResize
        options={{
          fullscreenControl: false,
          zoomControl: false,
        }}
        onGoogleApiLoaded={({ map, maps, ref }) => {
          mapref.current = ref;
          setMapInstance(map);
        }}
      >
        {Devices.map((d) => (
          <MapMarker
            lat={parseFloat(d.lat)}
            lng={parseFloat(d.long)}
            key={d.id}
            data={d}
            text={d.location}
            selected={d.dev_id === SelectedDevice?.dev_id}
            onClick={() => {
              setSelectedDevice(d);
            }}
          />
        ))}
      </GoogleMapReact>
      {!!SelectedDevice ? (
        <div
          className="bg-gray-700"
          style={{
            height: "calc(100vh - 72px)",
            overflowX: "hidden",
          }}
        >
          <div
            className={`p-2 pr-6 pt-4 mt-2 mx-2 flex flex-col justify-start items-stretch bg-gray-700 `}
          >
            <Select
              className="basic-single w-full text-black"
              classNamePrefix="select"
              styles={customStyles}
              isSearchable
              name="color"
              placeholder={SelectedDevice?.location}
              value={SelectedDevice?.location}
              onChange={(e: any) => {
                setSelectedDevice(Devices.find((d) => d.dev_id === e.value));
              }}
              options={DropDownListItems}
            />
            <div className="flex flex-row justify-start items-center text-gray-200 py-2 text-xl whitespace-nowrap">
              <div>Noise Level </div>
              <div className="text-gray-50 text-xl px-4">
                {SelectedDevice.laeqt} db(A)
              </div>
            </div>
            <div className="flex flex-row justify-start text-gray-200 py-1 text-sm">
              <div>Device </div>
              <div className="text-gray-50 text-sm font-bold px-4">
                {SelectedDevice.location}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 py-1 text-sm">
              <div>Zone </div>
              <div className="text-gray-50 text-sm font-bold px-4">
                {SelectedDevice.zone}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 py-1 text-sm">
              <div>District </div>
              <div className="text-gray-50 text-l font-bold px-5">
                {SelectedDevice.district}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 py-1 text-sm">
              <div>Timestamp </div>
              <div className="text-gray-50 text-l font-bold px-5 whitespace-nowrap">
                {moment(data?.created_at).format("MMMM Do YYYY, h:mm a")}
              </div>
            </div>
            <div className="flex flex-row justify-left items-center mt-4 overflow-y-auto">
              <table className="bg-gray-700 w-full table">
                <thead className="table-header-group">
                  <tr className="table-row">
                    <th className="p-2 text-white bg-gray-600 mx-1 text-left text-sm">
                      Parameter
                    </th>
                    <th className="p-2 text-white bg-gray-600 mx-1 text-left text-sm">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="table-row-group p-2 text-gray-200 text-sm">
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LAs</td>
                    <td className="p-2">{data?.las}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCs</td>
                    <td className="p-2">{data?.lcs}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZs</td>
                    <td className="p-2">{data?.lzs}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LAeqt</td>
                    <td className="p-2">{data?.laeqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCeqt</td>
                    <td className="p-2">{data?.lceqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZeqt</td>
                    <td className="p-2">{data?.lceqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LApeakt</td>
                    <td className="p-2">{data?.lapeakt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCpeakt</td>
                    <td className="p-2">{data?.lcpeakt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZpeakt</td>
                    <td className="p-2">{data?.lzpeakt}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MapView;
